import { Component, OnInit, Input, Output, EventEmitter, Inject, NgModule, ViewEncapsulation } from "@angular/core";
import { ShowLoginMessageData } from "src/app/infrastructure/message.data.types";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { KeyValuePair } from "src/app/infrastructure/types";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { PbIconComponent } from "src/app/controls/pb-icon/pb-icon.component";
import { AuthorizationService } from "src/app/services/authorization.module/authorization.service";
import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";

import { Messenger } from "src/app/services/messenger.module/messenger";
import { NgIf } from "@angular/common";


@Component({
    selector: 'favourite-switch',
    templateUrl: 'favourite-switch.component.html',
    styleUrls: ['favourite-switch.component.scss'],
    standalone: true,
    imports: [NgIf, PbIconComponent]
})

export class FavouriteSwitchComponent implements OnInit {
    private _isSelected: boolean = false;
    @Input() set isSelected(value: boolean) {
        this._isSelected = value;
    }
    get isSelected(): boolean {
        return this._isSelected;
    }
    @Output() requestSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() placeId: number;
    @Input() hasDescription: boolean;
    @Input() caption: string;

    constructor(
        private authorizationService: AuthorizationService,
        private applicationUrlsService: UrlHelpersService,
        
        private messenger: Messenger,
        private platformHelperService: PlatformHelpersService
    ) { }

    ngOnInit() { }

    OnClicked(e: any) {
        e.stopPropagation();
        e.preventDefault();
        if (!this.ShowSignUpIfLoggedOut()) {
            this.requestSelectedChange.emit(!this.isSelected);
        }
    }

    private ShowSignUpIfLoggedOut(): boolean {
        if (!this.authorizationService.isLoggedIn) {
            var returnUrl = this.applicationUrlsService.GetCurrentUrl();

            returnUrl = this.applicationUrlsService.AddQueriesToPath(returnUrl, [new KeyValuePair<string, string>("addFavouritePlaceId", this.placeId.toString())]);
            var loginMessageData: ShowLoginMessageData = {
                showOverlay: true,
                isRegistration: true,
                explicitRedirectUrl: returnUrl,
                registerText: [
                    "Placebuzz has much more to offer",
                    "Sign up to",
                    "keep",
                    "all your favourite properties in one place",
                ]
            }
            this.messenger.Send({
                messageType: MESSAGE_TYPES.SHOW_LOGIN,
                messageData: loginMessageData
            });

            this.platformHelperService.ScrollToTop();

            return true;
        }
        return false;
    }
}
